<template>
  <div>
    <div
      class="product-listing"
      v-if="
        ticket != null &&
        general_info != null &&
        general_info.lotteries[ticket.type_name] != null
      "
    >
      <v-layout row wrap mb-4>
        <template>
          <v-flex xs12 sm12 md12 lg12 xl12 mb12 text-center>
            <div class="justify-center py-2 mt-6">
              <v-container v-if="ticket != null">
                <v-row>
                  <v-col
                    ><v-img
                      :src="lotteries_info.lotteries[ticket.type_name].image"
                      max-width="200"
                    >
                    </v-img>
                  </v-col>
                  <v-col class="text-center align-center">
                    <h4
                      v-html="
                        $formatMoneyToWord(
                          general_info.lotteries[ticket.type_name].next_draw
                            .amount,
                          'US$',
                          'h2 style=\'display:inline;\''
                        )
                      "
                    ></h4>
                    <emb-countdown
                      :deadline="
                        general_info.lotteries[
                          ticket.type_name
                        ].next_draw.date.toDate()
                      "
                    >
                    </emb-countdown>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-flex>
          <v-flex xs12 sm6 md6 lg6 xl6 mb6 text-center>
            <div class="ticket_image align-center ticket pa-1">
              <img alt="product" :src="ticket.image" />
            </div>
          </v-flex>
        </template>
        <template>
          <v-flex xs12 sm6 md6 lg6 xl6 mb6 text-center>
            <div class="emb-card-content pa-4">
              <div v-if="freeLotteriesSubscriptions[ticket.id] == undefined">
                <div class="emb-meta-info layout align-center justify-center">
                  <v-btn
                    class="accent"
                    large
                    @click="playFreeTicket()"
                    v-if="getUser != null"
                  >
                    {{ $t("message.playFreePage.subscribeGroup") }}<br />{{
                      $t("message.playFreePage.subscribeGroupSmall")
                    }}</v-btn
                  >
                  <v-btn
                    class="red white--text"
                    large
                    v-else
                    to="/session/signin"
                  >
                    {{ $t("message.login") }}
                  </v-btn>
                </div>
                <div class="align-center layout justify-center">
                  <h4>{{ $t("message.playFreePage.or") }}</h4>
                </div>
              </div>
              <div v-else class="pb-6">
                <h6>
                  <v-icon medium color="green">mdi-check-bold</v-icon>

                  {{ $t("message.playFreePage.congratsAlready") }}
                </h6>
              </div>
              <div class="emb-meta-info layout align-center justify-center">
                <v-btn
                  class="primary"
                  large
                  :to="'/lotteries/' + ticket.type_name"
                  >{{ $t("message.playFreePage.buyOwn") }}</v-btn
                >
              </div>
            </div>
          </v-flex>
        </template>
      </v-layout>
    </div>

    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card class="py-6 px-4">
        <h4>
          {{ $t("message.playFreePage.congratsDialog") }}
        </h4>
        {{ $t("message.playFreePage.buyOwnDialog") }}
        <v-card-actions class="layout justify-center">
          <v-btn color="accent" @click="returnToFreeTickets">
            {{ $t("message.no") }}</v-btn
          >
          <v-btn color="accent" @click="goToBuyTicket">
            {{ $t("message.yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.ticket_image {
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.ticket {
  position: relative;
}
.ticket_date {
  position: absolute;
  bottom: 20px;
  background-color: #000000aa;
  color: white;
  font-size: 7vw;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  padding: 2px;
  width: 100%;
}
@media screen and (min-width: 400px) {
  .ticket_date {
    position: absolute;
    bottom: 20px;
    background-color: #000000aa;
    color: white;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    padding: 2px;
    width: 100%;
  }
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getUser",
      "freeLotteries",
      "freeLotteriesSubscriptions",
      "lotteries_info",
      "general_info",
    ]),
  },
  data() {
    return {
      ticket_id: null,
      ticket: null,
      dialog: false,
    };
  },
  watch: {
    "$store.state.freeLotteries": function () {
      this.updateTicket();
    },
    "$store.state.user": function () {
      if (this.getUser != null) {
        this.$store.dispatch("fetch_free_subscriptions");
      }
    },
  },
  methods: {
    returnToFreeTickets() {
      this.dialog = false;
      this.$router.push("/freeLotteries");
    },
    goToBuyTicket() {
      this.dialog = false;
      this.$gtag.event("goto_buy_from_free");
      this.$router.push("/lotteries/" + this.ticket.type_name);
    },
    updateTicket() {
      var result = this.freeLotteries.filter(
        (ticket) => ticket.id == this.ticket_id
      );
      if (result.length != 0) {
        this.ticket = result[0];
      } else {
        this.ticket = null;
      }
    },
    playFreeTicket() {
      this.$store.dispatch("play_free_ticket", this.ticket).then(() => {
        this.$gtag.event("free_lottery", {
          id: this.ticket.id,
          type_name: this.ticket.type_name,
        });
        this.dialog = true;
      });
    },
  },
  beforeMount() {
    this.ticket_id = this.$route.params.ticket_id;
    this.$store.dispatch("fetch_free_lotteries").then(() => {
      this.updateTicket();
    });
  },
};
</script>