import { render, staticRenderFns } from "./PlayFreeLottery.vue?vue&type=template&id=216f5ebe&scoped=true&"
import script from "./PlayFreeLottery.vue?vue&type=script&lang=js&"
export * from "./PlayFreeLottery.vue?vue&type=script&lang=js&"
import style0 from "./PlayFreeLottery.vue?vue&type=style&index=0&id=216f5ebe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216f5ebe",
  null
  
)

export default component.exports